// 第一引数: value
// 第二引数: rule

export default function Validation(value, rule) {

	// 必須チェック
	if('required' in rule){
		if(value === ''){
			return '必須項目です。'
		}
	}
	// 最大長チェック
	if('maxLength' in rule){
		if(value.length > rule.maxLength){
			return rule.maxLength+'文字以内で入力してください。'
		}
	}
	// 半角数字〇文字以内
	/*if('num11LengthShort' in rule){
		if(value !== ''){
			var reg = new RegExp(/^([1-9]\d*|0)(\.\d+)?$/);
		}
		if(value.length > rule.num11LengthShort || !value.match(reg)){
			return '半角数字'+rule.num11LengthShort+'文字以内で入力してください'
		}
	}*/
	//半角数字
	if('halfNum' in rule){
		if(value !== ''){
			var reg = new RegExp(/^([1-9]\d*|0)(\.\d+)?$/);
		}
		if(!value.match(reg)){
			return '半角数字で入力してください'
		}
	}

	// typeチェック
	if('type' in rule){
		var resultreg = '';
		switch(rule.type){
			case 'number':
				if(value !== ''){
					resultreg = new RegExp(/^([1-9]\d*|0)(\.\d+)?$/);
				}
				break;
			case 'email':
				resultreg = new RegExp(/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
				if(!value.match(resultreg)){
					return '有効なメールアドレス且つ、@以降も入力してください。';
				}
				break;
			default :
				break;
		}
		if(resultreg !== ''){
			if(!value.match(resultreg)){
				return '形式が異なります。';
			}
		}
	}
  return '';
}
