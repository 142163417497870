import React, { Component } from "react";
import { Switch, Route,BrowserRouter as Router } from "react-router-dom";

//遷移先
import RequestForm from './containers/RequestForm';
import Thanks from './containers/Thanks';






class App extends Component {
  render() {
    return (
          <Router>
            <Switch>
              {/*
                  フロントページ
              */}
              <Route exact path="/" component={RequestForm} />
              <Route exact path="/thanks" component={Thanks} />

              {/*
                  Infomation
              */}

              {/*
                  お問合せフォーム
              */}
              {/*テンプレート*/}


            </Switch>
          </Router>
    );
  }
}

//export default withAuthenticator(App);
export default App;
