//import Polyfill from './Polyfill'
//import 'babel-polyfill';
//import 'react-app-polyfill/ie11';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// ↓ この一行でnpm run buildの際にconsole.log()を何もしないメソッドにすり替え
process.env.NODE_ENV !== "development" && (console.log = () => {});

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
